<mat-form-field class="margin-left-small">
  <mat-label>Skriv för att söka</mat-label>
  <input #input (keyup)="applyFilter($event)" autocomplete="off" matInput>
</mat-form-field>

<div class="holder">
  <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table matSort>

    <caption>{{pleaseWait}}</caption>

    <!-- projectKey Column -->
    <ng-container matColumnDef="projectKey">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="projectKey" scope="col">Projektnummer</th>
      <td (click)="selectRow(row)" *matCellDef="let row" mat-cell>{{row.projectKey}}</td>
    </ng-container>

    <!-- projectName Column -->
    <ng-container matColumnDef="projectName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="projectName" scope="col">Projektnamn</th>
      <td (click)="selectRow(row)" *matCellDef="let row" mat-cell>{{row.projectName}}</td>
    </ng-container>

    <!-- projectDescription Column -->
    <ng-container matColumnDef="projectDescription">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="projectDescription" scope="col">Beskrivning
      </th>
      <td (click)="selectRow(row)" *matCellDef="let row" mat-cell>{{row.projectDescription}}</td>
    </ng-container>

    <!-- stagedVersion Column -->
    <ng-container matColumnDef="stagedVersion">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="stagedVersion" scope="col" style="width:10%;">
        stagedVersion
      </th>
      <td (click)="selectRow(row)" *matCellDef="let row" mat-cell>{{row.stagedVersion}}</td>
    </ng-container>

    <!-- deployProject Column -->
    <ng-container matColumnDef="deployProject">
      <th *matHeaderCellDef class="text-align-center narrow" mat-header-cell></th>
      <td *matCellDef="let row" class="text-align-center narrow" mat-cell>
        <mat-icon (click)="deployProject(row)">cloud_upload</mat-icon>
      </td>
    </ng-container>

    <!-- deleteRole Column -->
    <ng-container matColumnDef="deleteItem">
      <th *matHeaderCellDef class="text-align-center narrow" mat-header-cell></th>
      <td *matCellDef="let row" class="text-align-center narrow" mat-cell>
        <mat-icon class="mat-delete-icon" (click)="deleteItem(row)" color="warn">delete_outline</mat-icon>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row ">
      <td [colSpan]="displayedColumns.length" class="mat-cell no-data">Här var det tomt...</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="20" aria-label="Select page of projects"></mat-paginator>
</div>
