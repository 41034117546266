<div matDialogTitle>{{data.projectName}}</div>
<div matDialogContent>

  <p>Stage version: {{data.stagedVersion}}</p>

  <form [formGroup]="deployForm">
    <div class="margin-bottom-medium">
      <mat-form-field>
        <mat-label>Release level</mat-label>
        <mat-select
          (selectionChange)="this.deployForm.controls.release.setValue('')"
          formControlName="level" ngDefaultControl
        >
          <mat-option [value]=""></mat-option>
          <mat-option [value]="'PATCH'">PATCH</mat-option>
          <mat-option [value]="'MINOR'">MINOR</mat-option>
          <mat-option [value]="'MAJOR'">MAJOR</mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button (click)="stageProject()" [disabled]="!this.deployForm.controls.level.value || disableStageButton"
                color="primary"
                mat-raised-button>Stage
        </button>
      </div>
    </div>

    <div *ngIf="releases.length > 0" class="margin-bottom-medium">
      <mat-form-field>
        <mat-label>Releases</mat-label>
        <mat-select
          (selectionChange)="this.deployForm.controls.level.setValue('')"
          formControlName="release"
          ngDefaultControl>
          <mat-option></mat-option>
          <mat-option *ngFor="let release of releases" [value]="release">{{release}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button
          (click)="releaseProject()"
          [disabled]="!this.deployForm.controls.release.value || this.deployForm.controls.level.value !== '' || disableReleaseButton"
          color="primary"
          mat-raised-button>
          Release
        </button>
      </div>
    </div>
  </form>

  <button matDialogClose="undefined" mat-raised-button type="button">Stäng</button>
</div>
