import {Component, Inject, OnInit} from '@angular/core'
import {
  EXTREME_LONG_TIME_OUT,
  GitProject,
  LONG_TIME_OUT,
  TIME_OUT
} from '../../application/data-types'
import {DeployService} from '../../services/deploy.service'
import {FormGroup, FormControl} from '@angular/forms'
import {MatSnackBar} from '@angular/material/snack-bar'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'spb-gitlab',
  templateUrl: './gitlab.component.html',
  styleUrls: ['./gitlab.component.scss']
})
export class GitlabComponent implements OnInit {
  /**
   * Random variables
   */
  public projects: GitProject[] = []
  public project: GitProject | undefined = undefined
  public releases: string[] = []
  public disableStageButton = false
  public disableReleaseButton = false


  /**
   * The deployment form.
   */
  public deployForm: FormGroup = new FormGroup({
    project: new FormControl<string>('', {nonNullable: true}), // Actually the project number
    release: new FormControl<string>('', {nonNullable: true}), // A release number like 1.2.1
    level: new FormControl<string>('', {nonNullable: true}) // A release level like MINOR
  })

  constructor(
    private deployService: DeployService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: GitProject
  ) {
  }

  public ngOnInit(): void {
    this.fetchProjectReleases()
  }

  public stageProject(): void {
    this.disableStageButton = true

    this.snackBar.open('Staging av projektet förbereds ...', 'OK',
      {duration: LONG_TIME_OUT, panelClass: ['snackbar-green']})
    this.deployService.stageProject(this.data.projectKey, this.deployForm.controls.level.value)
      .subscribe({
        next: res => {
          this.fetchProjectReleases()
          this.snackBar
            .open(`Projektets staging av release ${res.version} har startat. ` +
              `Gå till pipeline (i GitLab) och avbryt det felaktiga bygget (som inte heter 'Bot Update of file')`,
              'OK', {duration: LONG_TIME_OUT, panelClass: ['snackbar-green']})
          this.deployForm.controls.level.setValue('')
        },
        error: () => {
          this.snackBar
            .open(`Någonting blev fel. Kontrollera om version redan finns på stage och samtidigt inte finns i produktion.`,
              'OK', {duration: EXTREME_LONG_TIME_OUT})
        }
      })
  }

  public releaseProject(): void {
    this.disableReleaseButton = true

    this.snackBar
      .open('Release påbörjas, vänligen vänta...', 'OK',
        {duration: TIME_OUT, panelClass: ['snackbar-green']})

    this.deployService.releaseProject(
      this.data.projectKey, this.deployForm.controls.release.value)
      .subscribe(() => {
        this.deployForm.controls.release.setValue('')
        this.snackBar
          .open('Release av projektet har startat. Merge requests har skapats (i GitLab)', 'OK',
            {duration: LONG_TIME_OUT, panelClass: ['snackbar-green']})
        this.deployForm.controls.release.setValue('')
      })
  }

  public fetchProjectReleases(): void {
    this.deployService.getReleasesForProject(this.data.projectKey)
      .subscribe(
        {
          next: (releases: string[]) => {
            /**
             * Sorts an array of version strings in descending order.
             *
             * @param a - The first version string to compare.
             * @param b - The second version string to compare.
             */
            const compareVersions = (a: string, b: string): number => (
              b.split('.')
                .map(Number)
                .reduce((acc: number, val: number, index: number) => acc || val - parseInt(a.split('.')[index]), 0)
            )
            this.releases = releases.sort(compareVersions)
            this.data.stagedVersion = releases[0]
          },
          error: () => {
            this.snackBar.open('Antingen är ditt accessToken felaktigt eller så finns inte projektet', 'OK',
              {duration: LONG_TIME_OUT, panelClass: ['snackbar-red']})
          }
        }
      )
  }
}
