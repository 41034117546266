import {Component, OnInit} from '@angular/core'
import {AccessTokenComponent} from '../access-token/access-token.component'
import {MatDialog} from '@angular/material/dialog'
import {DeployService} from '../../services/deploy.service'

@Component({
  selector: 'spb-deploy-home',
  templateUrl: './deploy-home.component.html',
  styleUrls: ['./deploy-home.component.scss']
})
export class DeployHomeComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private deployService: DeployService
  ) {
  }

  public ngOnInit(): void {
    if (!this.deployService.getGitLabToken()) {
      this.openAccessToken()
    }
  }

  /**
   * Open Access token dialog
   */
  public openAccessToken() {
    this.dialog.open(AccessTokenComponent)
  }
}
